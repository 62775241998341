import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Holding.css";

function Holding() {
	const nav = useNavigate();

	const goAdmin = () => {
		nav("/admin");
	};
	return (
		<div id="main" className="holding">
			<header id="headerLogos">
				<div id="eventLogo" />
				<div id="brandLogo" />
			</header>
			<div id="pageContainer">
				<div id="pageContentScroll">
					<div id="pageContent">
						<div id="holdingTop"></div>
						<div id="holdingLogo"></div>
						<div id="holdingBottom">
							<Button
								className="neon blue"
								variant="outlined"
								size="large"
								onClick={goAdmin}
							>
								Sign In
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Holding;
