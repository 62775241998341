import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Holding from "./pages/Holding";

import "./index.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { StyledEngineProvider } from "@mui/material";

const Admin = lazy(() => import("./pages/Admin"));

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#c3002f",
		},
		secondary: {
			main: "#efefef",
		},
		background: {
			default: "#000000",
			paper: "#000000",
		},
	},
});

root.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<Suspense>
					<BrowserRouter basename="/">
						<Routes>
							<Route path="/admin" exact element={<Admin />} />
							<Route path="/" element={<Holding />} />
							<Route path="*" element={<Holding />} />
						</Routes>
					</BrowserRouter>
				</Suspense>
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>
);
